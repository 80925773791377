import React, {
  forwardRef,
  memo, useCallback,
} from 'react';
import cx from 'classnames';
import Loader from 'react-loader-spinner';
import {
  Card,
  // Pagination,
} from '@components';
import {
  Colors, Proposal, ProposalType,
} from '@types';

import styles from './styles.module.scss';
import { EmptyTab } from './EmptyTab';
// import { EmptyTab } from './EmptyTab';

type ProposalsSingleTabContentProps = {
  cards: Proposal[],
  cardsPerPage: number,
  cardsTotal: number,
  classNameTab?: string,
  classNameGridWrapper: string,
  isAddressVerified?: boolean;
  isDocVerified?: boolean;
  isLoading?: boolean;
  isProposalsScreen?: boolean;
  tabKey?: ProposalType;
  submitNewPageNumber?: (page: number) => void;
  onOpenProposalClick?: (hash: string) => void;
  isSearch?: boolean;
};

const ProposalsSingleTabContent = memo<ProposalsSingleTabContentProps>(
  forwardRef<HTMLDivElement, ProposalsSingleTabContentProps>(({
    cards,
    // cardsPerPage,
    // cardsTotal,
    classNameTab,
    classNameGridWrapper,
    // isAddressVerified,
    isLoading,
    // isProposalsScreen,
    // tabKey,
    // submitNewPageNumber,
    onOpenProposalClick,
    // isSearch,
  }, ref) => {
    // const {
    //   pagesText,
    // // pageCount,
    // // onPageChange,
    // } = useCardsPagination({
    //   cardsTotal,
    //   cardsPerPage,
    //   submitNewPageNumber,
    // });

    const renderLoader = useCallback(() => (
      <div className={styles.loaderContainer}>
        <Loader
          type="Rings"
          color={Colors.gold}
          height={100}
          width={100}
          timeout={0}
        />
      </div>
    ), []);

    // const isNeedLandBaseVerification = tabKey !== ProposalType.CryptoVoting;
    const renderCards = useCallback(() => (
      cards.map((proposal) => (
        <Card
          proposal={proposal}
          className={styles.cardWrapper}
          key={proposal.id}
          onOpenClick={onOpenProposalClick}
        />
      ))), [cards, onOpenProposalClick]);

    // @TODO
    // if (!isAddressVerified && isNeedLandBaseVerification
    // if (
    //   // !isProposalsScreen
    //   //  !isLoading
    // ) {
    //   return (
    //     <EmptyTab
    //       isNeedVerify
    //     />
    //   );
    // }

    // if (cards.length === 0 && !isLoading) {
    //   return (
    //     <EmptyTab
    //       isProposalsScreen={isProposalsScreen}
    //       isSearch={isSearch}
    //       tabKey={tabKey}
    //     />
    //   );
    // }

    return (
      <div className={cx(styles.tabContent, classNameTab)}>

        { isLoading && cards.length === 0 ? renderLoader()
          : (
            <div className={cards.length ? classNameGridWrapper : ''}>
              {renderCards()}

              <div className={styles.pagesInfo}>
                <div ref={ref} style={{ height: '5px' }} />
                {/* <Text type="span" className={styles.pagesText}>
            {pagesText}
            </Text> */}
                {/* <Pagination
          pageCount={pageCount}
          onPageChange={onPageChange}
          /> */}
              </div>
              {!isLoading && cards.length === 0 && (<EmptyTab />)}
            </div>
          )}

      </div>
    );
  }),
);

export { ProposalsSingleTabContent };
