export enum RouteName {
  home = '/',
  proposals = '/proposals',
  profile = '/profile',
  proposalStore = '/proposal-store',
  admin = '/admin',
  proposalCreation = '/proposal-creation',
  error = '/error',
  bridge = '/bridge',
}
