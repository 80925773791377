import React from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import {
  Router, Route, Redirect, Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelectors } from '@store/auth/selectors';
import {
  HomePage,
  ProfilePage,
  ProposalsPage,
  AdminPage,
  RpvStorePage,
  ProposalItemPage,
  ProposalCreationPage,
  ErrorPage,
  BridgePage,
} from '@pages';
import { Layout } from '@containers';
import { getLibrary, history } from '@utils';
import { RouteName } from '@types';

import '@assets/fontello/css/fontello.css';
import './styles.scss';

const App = () => {
  // const { isVerified } = useKyc();
  const token = useSelector(authSelectors.getProp('token'));
  const isAdmin = useSelector(authSelectors.isAdmin());

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Router history={history}>
        <Switch>
          <Route path={RouteName.home} exact>
            <HomePage />
          </Route>
          <Layout>
            <Route path={RouteName.profile} exact>
              {token ? <ProfilePage /> : <Redirect to={RouteName.home} /> }
            </Route>
            <Route path={RouteName.proposals} exact>
              {token ? <ProposalsPage /> : <Redirect to={RouteName.home} /> }
            </Route>
            <Route path={RouteName.proposalStore} exact>
              {token ? <RpvStorePage /> : <Redirect to={RouteName.home} /> }
            </Route>
            <Route path={RouteName.bridge} exact>
              {token ? <BridgePage /> : <Redirect to={RouteName.home} /> }
            </Route>
            {isAdmin && (
              <Route path={RouteName.admin} exact>
                <AdminPage />
              </Route>
            )}
            <Route path={RouteName.proposalCreation} exact>
              {token ? <ProposalCreationPage />
                : <Redirect to={RouteName.home} /> }
            </Route>
            <Route exact path={`${RouteName.proposals}/:id`}>
              {token ? <ProposalItemPage /> : <Redirect to={RouteName.home} /> }
            </Route>
            <Route path={RouteName.error}>
              <ErrorPage />
            </Route>
            <Redirect to={RouteName.error} />
          </Layout>
        </Switch>
      </Router>
    </Web3ReactProvider>
  );
};

export { App };
