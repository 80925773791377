import { ProposalType } from '@types';
import { useState, useEffect, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';

type Options = {
  limit?: number;
  offset?: number;
  name?: string;
  creator?: string;
  proposalType?: ProposalType;
  voter?: string
};

type FetchData = (options: Options) =>
Promise<{ data: any; total: number, offset:number, limit: number }>;

export const useInfiniteScroll = (fetchData: FetchData, initialOptions: Options = {
  limit: 20,
  offset: 0,
}) => {
  const { ref, inView } = useInView({
    threshold: 1.0,
  });

  const [data, setData] = useState<any[]>([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [total, setTotal] = useState(0);
  const limit = initialOptions.limit || 20;

  const loadMore = useCallback(async () => {
    if (isLoading || !hasMore) return;

    setIsLoading(true);
    try {
      const response = await fetchData({ ...initialOptions, limit, offset });
      const { data: newData, total: newTotal } = response;

      if (newData?.length > 0) {
        setData((prevData) => [...prevData, ...newData]);
        setOffset((prevOffset) => +prevOffset + +limit);
        setTotal(newTotal);

        // Если загружено все данные
        if (data.length + newData.length >= newTotal) {
          setHasMore(false);
        }
      } else {
        setHasMore(false); // Останавливаем загрузку, если данных больше нет
      }
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [fetchData, offset, limit, isLoading, hasMore, data.length, initialOptions]);

  useEffect(() => {
    if (inView && hasMore) {
      loadMore();
    }
  }, [inView, hasMore, loadMore]);

  useEffect(() => {
    setData([]);
    setOffset(0);
    setHasMore(true);
    setTotal(0);
  }, [JSON.stringify(initialOptions)]);

  return {
    data, ref, isLoading, hasMore, total,
  };
};
