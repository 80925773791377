import React, { useState, useCallback, useMemo } from 'react';
import {
  Text,
  // Modal,
  SearchInput,
} from '@components';
import { ProposalType } from '@types';
import {
  // useGetProposalsList, useGetProposalsSearch,
  useInfiniteScroll,
} from '@hooks';
import { useSelector } from 'react-redux';
import { authSelectors } from '@store/auth/selectors';
import { profileSelectors } from '@store/profile/selectors';
import api from '@api';
import { useProposalsModal } from './hooks';
// import { CARDS_PER_PAGE } from './constants';
import { TabsProposals } from './components/TabsProposals';
import styles from './styles.module.scss';

const fetchProposals = async ({
  limit, offset, name,
}: {
  limit?: number, offset?: number, name?:string,
}) => {
  try {
    const { data } = await api.get('/proposal/search', {
      params: {
        name,
        offset,
        limit,
      },
    });

    return data;
  } catch (error) {
    console.error('Error loading data:', error);

    return {
      data: [],
      limit: 0,
      offset: 0,
      total: 0,
    };
  }
};

const Proposals = () => {
  const [searchInput, setSearchInput] = useState('');
  const hasPassport = useSelector(authSelectors.getProp('hasPassport'));
  // const { isVerified, handleVerifyClick, isAddressVerified } = useKyc();
  const location = useSelector(profileSelectors.getProp('location'));
  const isAddressVerified = useMemo(() => hasPassport && Boolean(location?.country?.value),
    [hasPassport, location?.country?.value]);
  const [tabKey, setTabKey] = useState<ProposalType>(ProposalType.CryptoVoting);
  const handleVerifyClick = () => {
    window.open(process.env.REACT_APP_REPRESENT_PASSPORT_URL, '_blank', 'noopener,noreferrer');
  };
  const { data, ref, isLoading } = useInfiniteScroll(fetchProposals, {
    // creator,
    name: searchInput,
    proposalType: tabKey,
  });

  const {
    // isVerifyModalOpened,
    // verifyAccountButtons,
    // closeModal,
    handleShowModal,
  } = useProposalsModal(
    hasPassport,
    handleVerifyClick,
  );

  const isSearchInputEmpty = useMemo(
    () => searchInput.replace(/\s/g, '') === '',
    [searchInput],
  );

  // const {
  //   commonProposalsList,
  //   commonProposalsTotal,
  //   isProposalsListLoading,
  // } = useGetProposalsList({
  //   pageLimit: CARDS_PER_PAGE,
  //   proposalType: tabKey,
  //   page,
  //   query: searchInput,
  // });

  // const {
  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   searchProposalsList,
  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   searchProposalsTotal,
  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   isProposalsSearchLoading,
  // } = useGetProposalsSearch({
  //   query: searchInput,
  //   proposalType: tabKey,
  //   page,
  //   pageLimit: CARDS_PER_PAGE,
  // });

  const handleSearchChange = useCallback((input: string) => {
    setSearchInput(input);
  }, []);

  return (
    <section className={styles.container}>
      <div className={styles.titleWrapper}>
        <Text type="h3" className={styles.title}>
          PROPOSALS
        </Text>
      </div>
      <div className={styles.inputWrapper}>
        <SearchInput
          value={searchInput}
          onChange={handleSearchChange}
          className={styles.searchInput}
        />
        {/* <Button onClick={handleCreateProposal} className={styles.smallButton}>
          <Icon
            value={Icons.plus}
            className={styles.smallButtonPlus}
          />
        </Button> */}
        {/* <Button onClick={handleCreateProposal} className={styles.bigButton}>
          <Icon value={Icons.plus} className={styles.plusIcon} />
          Create new proposal
        </Button> */}
      </div>
      {/* {isSearchInputEmpty ? ( */}
      <TabsProposals
        isLoading={isLoading}
        proposals={data}
        isAddressVerified={isAddressVerified}
        isVerified={hasPassport}
        // total={commonProposalsTotal}
        handleShowModal={handleShowModal}
        setCurrentTabKey={setTabKey}
        setCurrentPage={() => {}}
        isSearch={!isSearchInputEmpty}
        // @ts-ignore
        ref={ref}
      />
      {/* ) : (
        <TabsProposals
          isLoading={isProposalsSearchLoading}
          proposals={searchProposalsList}
          isAddressVerified={isAddressVerified}
          isVerified={hasPassport}
          total={searchProposalsTotal}
          handleShowModal={handleShowModal}
          setCurrentTabKey={setTabKey}
          setCurrentPage={setPage}
          isSearch
        />
      )} */}
      {/* <Modal
        title="please purchase the nft passport "
        description="You have to mint Represent
        ID NFT passport and hold it in the connected wallet to participate
         in any proposals, please visit Represent ID
          service, go through the verification process and mint your own passport."
        isOpen={isVerifyModalOpened}
        onClose={closeModal}
        buttons={verifyAccountButtons}
      /> */}
    </section>
  );
};

export { Proposals };
