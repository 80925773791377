import React, {
  useMemo, useCallback, memo,
  forwardRef,
} from 'react';
import { Tabs, ProposalsSingleTabContent } from '@components';
import {
  ContentTabs,
  ProposalType,
  RouteName,
  Proposal,
} from '@types';
import { history } from '@utils';
import {
  CARDS_PER_PAGE,
  headers,
  tabKeys,
} from '../../constants';
import styles from './styles.module.scss';

type TabsProposalsProps = {
  isLoading: boolean;
  isSearch?: boolean;
  isAddressVerified: boolean;
  isVerified: boolean;
  proposals: Proposal[];
  total: number;
  handleShowModal: () => void;
  setCurrentTabKey: (tabKey: ProposalType) => void;
  setCurrentPage: (page: number) => void;
};

const TabsProposals = memo<TabsProposalsProps>(
  forwardRef<HTMLDivElement, TabsProposalsProps>(({
  // isAddressVerified,
  // isVerified,
    isLoading,
    isSearch,
    proposals,
    total,
    handleShowModal,
    setCurrentTabKey,
    setCurrentPage,
  }, ref) => {
    const submitTabKey = useCallback((tabKey) => {
      setCurrentTabKey(tabKey);
    }, [setCurrentTabKey]);

    const submitNewPageNumber = useCallback((page: number) => {
      setCurrentPage(page);
    }, [setCurrentPage]);

    const onOpenProposalClick = useCallback((hash: string) => {
      history.push(`${RouteName.proposals}/${hash}`);
    }, []);

    const tabContents = useMemo(
      () => tabKeys.map((tabKey) => (
        <ProposalsSingleTabContent
          key={tabKey}
          isLoading={isLoading}
          cards={proposals}
          cardsTotal={total}
          cardsPerPage={CARDS_PER_PAGE}
          classNameGridWrapper={styles.cardsGridWrapper}
          isProposalsScreen
          tabKey={tabKey}
          submitNewPageNumber={submitNewPageNumber}
          onOpenProposalClick={onOpenProposalClick}
          isSearch={isSearch}
          ref={ref}
        />
      )), [
        isLoading,
        isSearch,
        proposals,
        total,
        submitNewPageNumber,
        onOpenProposalClick,
        ref],
    );

    const content: ContentTabs = {
      headers,
      tabContents,
    };

    // const disabledTabKeys = useMemo(
    //   () => {
    //     const disabledArray = [];
    //     if (!isAddressVerified) {
    //       disabledArray.push(ProposalType.LandBasedVoting);
    //     }
    //     if (!isVerified) {
    //       disabledArray.push(ProposalType.OrganizationalVoting);
    //     }
    //     return disabledArray;
    //   },
    //   [isAddressVerified, isVerified],
    // );

    return (
      <Tabs
        content={content}
        className={styles.tabsContainer}
        classNameHeader={styles.tabsHeader}
        handleShowModal={handleShowModal}
        disabledTabKeys={[]}
        tabKeys={tabKeys}
        submitTabKey={submitTabKey}
        isTwoTabs
      />
    );
  }),
);

export { TabsProposals };
