import { oneInchOracleAbi } from '@assets/abi';
import { ONE_INCH_ORACLE_ADDRESS, USDT_MAINNET_ADDRESS } from '@constants';
import { BigNumber, ethers, utils } from 'ethers';
import { useEffect, useState } from 'react';

const token = {
  address: USDT_MAINNET_ADDRESS,
  decimals: 6,
};

export const useEthPrice = () => {
  const [ethPrice, setEthPrice] = useState('');

  async function getPrice() {
    const provider = new ethers.providers.JsonRpcProvider('https://1rpc.io/eth');

    const contract = new ethers.Contract(ONE_INCH_ORACLE_ADDRESS, oneInchOracleAbi, provider);
    try {
      const rate = await contract.getRateToEth(token.address, true);
      const numerator = BigNumber.from(10).pow(token.decimals);
      const denominator = BigNumber.from(10).pow(18);
      const price = BigNumber.from(rate).mul(numerator).div(denominator);
      setEthPrice(utils.formatUnits(price));
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 10_000);

    getPrice();

    return () => clearInterval(interval);
  }, []);

  return { ethPrice };
};
