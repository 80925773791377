// import { useEffect } from 'react';
import { /* useDispatch, */ useSelector } from 'react-redux';
// import { proposalsGetProposalsSearch } from '@store/proposals/actionCreators';
import { proposalsSelectors } from '@store/proposals/selectors';
import { /* GetProposalsSearchPayload , */ ProposalType } from '@types';

type UseGetProposalsSearchProps = {
  query: string;
  proposalType: ProposalType;
  page: number,
  pageLimit: number;
};

export function useGetProposalsSearch({
  // query,
  // proposalType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  page,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  pageLimit,
}: UseGetProposalsSearchProps) {
  // const dispatch = useDispatch();
  const isProposalsSearchLoading = useSelector(proposalsSelectors.getProp('isProposalsSearchLoading'));
  // const searchProposalsList = useSelector(proposalsSelectors.getProp('searchProposalsList'));
  const searchProposalsTotal = useSelector(proposalsSelectors.getProp('searchProposalsTotal'));
  const commonProposalsList = useSelector(proposalsSelectors.getProp('commonProposalsList'));

  const filteredByName = commonProposalsList;// .filter((item) => item.name.includes(query));
  const searchProposalsList = filteredByName;

  // @TODO return logic when search is ready
  // useEffect(() => {
  //   if (query.replace(/\s/g, '') !== '') {
  //     const pageOffset = page * pageLimit - pageLimit;

  //     const payload: GetProposalsSearchPayload = {
  //       query,
  //       pageLimit,
  //       pageOffset,
  //       proposalType,
  //     };
  //     dispatch(proposalsGetProposalsSearch(payload));
  //   }
  // }, [page, dispatch, pageLimit, proposalType, query]);

  return {
    isProposalsSearchLoading,
    searchProposalsList,
    searchProposalsTotal,
  };
}
