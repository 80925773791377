import {
  call, takeLeading, put,
  select,
} from 'redux-saga/effects';
import { sagaErrorHandler } from '@utils';
import { createVoting } from '@api/ethereum';
import { ProposalItemActionTypes } from '@store/proposalItem/actionTypes';
import { proposalItemCreate, proposalItemSetState } from '@store/proposalItem/actionCreators';

import { toastClose } from '@components';
import { createProposal } from '@api/back';
import { Unwrap } from '@types';

// const qtyVotersPayload = 1;
// const minPercentageVotersPayload = 50;

function* createProposalSaga({ payload }: ReturnType<typeof proposalItemCreate>) {
  try {
    yield put(proposalItemSetState({ isCreatingProposal: true }));
    const {
      time, date, qtyVoters, minQtyVoters, description, name, image, keywords, imageMimeType,
    } = payload;
    date!.setHours(+time!.split(':')[0]);
    date!.setMinutes(+time!.split(':')[1]);
    date!.setSeconds(0);

    const now = +((Date.now() / 1000).toFixed(0));
    const dateSec = +(+date!.getTime() / 1000);
    let duration = +((dateSec - now).toFixed(0));
    duration = Math.round(duration / 60) * 60;
    // const land = { country: payload.land.country };
    // const organizationEmployees = payload.organizationEmployees
    //   ? payload.organizationEmployees : [''];

    // const expiresAt = new Date(dateSec * 1000).toISOString();

    // const proposalAddress:Unwrap<typeof getCreatingContractAddress> = yield call(
    //   // @ts-ignore
    //   getCreatingContractAddress,
    //   salt,
    //   duration,
    //   qtyVotersPayload,
    //   minPercentageVotersPayload,
    // );

    const res: Unwrap<typeof createProposal> = yield call(createProposal, {
      name,
      address: '0x1111111111111111111111111111111111111111',
      image,
      imageMimeType,
      proposalType: 'CryptoVoting',
      description,
      // organizationEmployees:string[]
      // land: {},
      keywords,
      duration,
      expiresAt: new Date(Date.now() + duration * 1000).toISOString(), // ISO
      qtyVoters,
      minPercentageVoters: Math.floor((+minQtyVoters / +qtyVoters) * 100),
    });

    const { priceForCreateWei } = yield select((state) => state.proposalItem);
    const a = yield call(
      createVoting,
      +duration,
      +qtyVoters,
      +minQtyVoters,
      JSON.stringify({ id: res.id, desc: description }),
      priceForCreateWei,
    );

    console.log(a);
    toastClose();

    yield put(
      proposalItemSetState({
        isSuccessCreate: true,
        isCreatingProposal: false,
      }),
    );
  } catch (error) {
    yield put(proposalItemSetState({ isCreatingProposal: false }));
    sagaErrorHandler(error as string);
  }
}

export function* proposalItemCreateSaga() {
  yield takeLeading(
    ProposalItemActionTypes.CREATE_PROPOSAL,
    createProposalSaga,
  );
}
