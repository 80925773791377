import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { ImageIpfs } from '@components';

import { Proposal, ProposalType } from '@types';
import { formatTimeDifference } from '@utils';
import { chains } from '@constants';
import { Text } from '../Text';
import styles from './styles.module.scss';

type CardProps = {
  className?: string;
  proposal: Proposal;
  onOpenClick?: (hash: string) => void;
};

const Card = ({
  className = '',
  proposal,
  onOpenClick,
}: CardProps) => {
  const {
    proposalType,
    hash,
    expiresAt = 0,
    imageHash,
    name,
    description,
    keywords,
    proposalEvent,
    // totalVoters, minConsensusVoters,
  } = proposal as Proposal;

  const total = proposalEvent?.voteEvents?.length || 0;
  const forStat = proposalEvent?.voteEvents?.filter((el) => el.choice === 1).length || 0;
  const against = proposalEvent?.voteEvents?.filter((el) => el.choice === 2).length || 0;
  const [timeRemain, setTimeRemain] = useState('');
  const chain = chains.find((el) => el.alias === proposalEvent.network);
  const onClick = useCallback(() => {
    if (onOpenClick !== undefined) {
      onOpenClick(hash);
    }
  }, [onOpenClick, hash]);

  useEffect(() => {
    const t = setInterval(() => {
      const timeLeft = new Date(expiresAt).getTime() - Date.now();

      setTimeRemain(formatTimeDifference(timeLeft));
    }, 1000);

    return () => clearInterval(t);
  }, [expiresAt]);

  return (
    <button
      type="button"
      className={cx(styles.card, className)}
      onClick={onClick}
    >
      <div className={styles.wrapperImg}>
        <ImageIpfs imageHash={imageHash} className={styles.image} />
      </div>
      <div className={styles.containerInfo}>
        <div style={{ width: '100%' }}>
          <div className={styles.titleLine}>

            <Text type="h3" className={styles.title}>
              {name}
            </Text>
            <div className={styles.type}>
              {chain && (
              <div className={styles.chain}>
                <img src={chain.icon} alt="" />
                {chain.chainName}
              </div>
              )}

              {proposalType === ProposalType.LandBasedVoting ? 'Land' : 'Crypto'}
            </div>
          </div>

          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />

          <div className={styles.tags}>
            {keywords?.map((el) => <div className={styles.tag} key={Math.random()}>{`@${el}`}</div>)}
          </div>

          <div className={styles.progressWrapper}>
            {+total
              ? (

                <div className={styles.progress}>
                  <div className={styles.stat}>
                    Yes
                    {`(${((+forStat / +total) * 100).toFixed(0)}%)`}
                  </div>
                  <div className={styles.stat}>
                    No
                    {`(${((+against / +total) * 100).toFixed(0)}%)`}
                  </div>
                  <div
                    className={styles.progressBar}
                    style={{ width: `${(+forStat / +total) * 100}%` }}
                  />
                </div>

              ) : (
                <div className={styles.progress}>
                  <div className={styles.stat}>
                    Yes (0%)
                  </div>
                  <div className={styles.stat}>
                    No (0%)
                  </div>
                  <div className={styles.progressBar} style={{ width: '50%' }} />
                </div>
              )}
          </div>

          <div className={styles.votesLine}>
            {+total
              ? (
                <>
                  <div className={styles.votes}>
                    {forStat || 0}
                    {' '}
                    votes
                  </div>
                  <div className={styles.votes}>
                    {against || 0}
                    {' '}
                    votes
                  </div>
                </>
              ) : null}
          </div>
        </div>
        <div className={styles.timeRemain}>{timeRemain}</div>
      </div>
    </button>
  );
};

export { Card };
