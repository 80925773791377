import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { proposalsGetProfileProposals } from '@store/proposals/actionCreators';
import { proposalsSelectors } from '@store/proposals/selectors';
import { GetProposalsListPayload, ProposalType } from '@types';

type UseGetProposalsProfileProps = {
  pageLimit: number;
  tabKeys: ProposalType[];
  query: string
};

export function useGetProfileProposals({
  pageLimit,
  tabKeys,
  query = '',
}: UseGetProposalsProfileProps) {
  const dispatch = useDispatch();

  const initialState = {
    proposalType: tabKeys[0],
    page: 1,
  };

  const [state, setState] = useState(initialState);

  const isProfileProposalsLoading = useSelector(proposalsSelectors.getProp('isProfileProposalsLoading'));
  const profileProposals = useSelector(proposalsSelectors.getProp('profileProposals'));
  const profileProposalsTotal = useSelector(proposalsSelectors.getProp('profileProposalsTotal'));

  const { page, proposalType } = state;

  const setTabKey = useCallback((tabKey: ProposalType) => {
    setState({
      proposalType: tabKey,
      page: 1,
    });
  }, []);

  const setPage = useCallback((nextPage: number) => {
    setState({
      ...state,
      page: nextPage,
    });
  }, [state]);

  useEffect(() => {
    const pageOffset = page * pageLimit - pageLimit;

    const payload: GetProposalsListPayload = {
      pageLimit,
      pageOffset,
      proposalType,
      query,
    };
    dispatch(proposalsGetProfileProposals(payload));
  }, [
    page,
    dispatch,
    pageLimit,
    proposalType,
  ]);

  return {
    isProfileProposalsLoading,
    profileProposals,
    profileProposalsTotal,
    setTabKey,
    setPage,
  };
}
