import api from '@api';
import { throwApiError } from '@utils';
import {
  ProposalRaw,
  ProposalItem,
  ProposalListResponse,
  Proposal,
} from '@types';

export const getProfileProposals = async (
  requestUrl: string,
): Promise<ProposalListResponse> => {
  const { data } = await api.get(requestUrl);
  if (data.error) {
    throwApiError(data);
  }

  return { list: data?.data, total: data.total };
};

export const getProposalsList = async (requestUrl: string): Promise<ProposalListResponse> => {
  const { data } = await api.get(requestUrl);
  if (data.error) {
    throwApiError(data);
  }

  return {
    list: data.map((el: any) => ({
      ...el,
      hash: el?.proposalInfo?.hash,
      expiresAt: el?.proposalInfo?.expiresAt,
      imageHash: el?.proposalInfo?.imageHash,
      name: el?.proposalInfo?.name,
      description: el?.proposalInfo?.description,
      keywords: el?.proposalInfo?.keywords,

    })),
    // list:  data.data
    total: data.total,
  };
};

export const getProposalsSearch = async (
  requestUrl: string,
): Promise<{ list: ProposalRaw[], total: number }> => {
  const { data } = await api.get(`/proposal/search${requestUrl}`);

  if (data.error) {
    throwApiError(data);
  }
  return { list: data?.data, total: data.total };
};

export const getProposal = async (hashItem: string): Promise<Proposal> => {
  const { data } = await api.get(`/proposal/get/${hashItem}`);

  if (data.error) {
    throwApiError(data);
  }

  return data;
};

export const addToAllowList = async (address: string) => {
  const { data } = await api.post('/bc-gate/add_to_allow_list', { contract_id: address });

  if (data.error) {
    throwApiError(data);
  }
};

export const createProposal = async (proposal: {
  name:string,
  address: string,
  image:string,
  imageMimeType:string,
  proposalType: number,
  description:string,
  // organizationEmployees:string[]
  // land: {},
  keywords:string[],
  duration:string,
  expiresAt: string // ISO
  qtyVoters:number,
  minPercentageVoters:number
}): Promise<ProposalItem> => {
  const { data } = await api.post('/proposal/create', proposal);

  if (data.error) {
    throwApiError(data);
  }

  return data;
};

export const getSignatureBridgeCreateProposal = async (bridgeProposalData:{
  'network': string
  'proposalType':number
  'duration':number
  'totalVoters':number
  'minConsensusVoters': number
  'description': string
  'whitelist': string[]
}) => {
  const { data } = await api.post('/profile/bridge-create-proposal', bridgeProposalData);

  if (data.error) {
    throwApiError(data);
  }

  return data;
};
