import { call, put, takeLatest } from 'redux-saga/effects';
import { ProposalsActionTypes } from '@store/proposals/actionTypes';
import { Unwrap } from '@types';
import {
  proposalsGetProposalsList,
  proposalsSetState,
} from '@store/proposals/actionCreators';
import { getProposalsList } from '@api/back';
import { sagaErrorHandler } from '@utils';

function* getProposalsListSaga({ payload }: ReturnType<typeof proposalsGetProposalsList>) {
  try {
    yield put(proposalsSetState({
      isProposalsListLoading: true,
      commonProposalsList: [],
      commonProposalsTotal: 0,
    }));

    const {
      pageLimit,
      pageOffset,
      proposalType,
      query,
    } = payload;

    const requestUrl = `proposal-events/search?proposalType=${proposalType}&limit=${pageLimit}&offset=${pageOffset}&name=${query}`;

    const
      {
        list: proposalsListRaw,
      // total,
      }
      : Unwrap<typeof getProposalsList> = yield call(
        getProposalsList, requestUrl,
      );

    yield put(proposalsSetState({
      commonProposalsList: proposalsListRaw,
      commonProposalsTotal: 0, // total,
      isProposalsListLoading: false,
    }));
  } catch (error) {
    yield put(proposalsSetState({ isProposalsListLoading: false }));
    // @ts-ignore
    sagaErrorHandler(error);
  }
}

export function* proposalsGetProposalsListSaga() {
  yield takeLatest(ProposalsActionTypes.GET_PROPOSALS_LIST, getProposalsListSaga);
}
