import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { proposalsGetProposalsList } from '@store/proposals/actionCreators';
import { proposalsSelectors } from '@store/proposals/selectors';
import { GetProposalsListPayload, ProposalType } from '@types';

type UseGetProposalListProps = {
  pageLimit: number;
  proposalType: ProposalType;
  page: number,
  query?: string;
  creator?: string;
};

export function useGetProposalsList({
  pageLimit,
  proposalType,
  page,
  query = '',
  creator = '',
}: UseGetProposalListProps) {
  const dispatch = useDispatch();

  const isProposalsListLoading = useSelector(proposalsSelectors.getProp('isProposalsListLoading'));
  const commonProposalsList = useSelector(proposalsSelectors.getProp('commonProposalsList'));
  const commonProposalsTotal = useSelector(proposalsSelectors.getProp('commonProposalsTotal'));

  useEffect(() => {
    const pageOffset = page * pageLimit - pageLimit;
    const payload: GetProposalsListPayload = {
      pageLimit,
      pageOffset,
      proposalType,
      query: query.trim(),
      creator: creator.trim(),
    };
    dispatch(proposalsGetProposalsList(payload));
  }, [
    page,
    dispatch,
    pageLimit,
    proposalType,
    query,
    creator,
  ]);

  return {
    isProposalsListLoading,
    commonProposalsList,
    commonProposalsTotal,
  };
}
