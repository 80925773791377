import { Chain } from '@types';
import arbitrumIcon from '@assets/images/arbitrum.svg';
import baseIcon from '@assets/images/base.svg';
import optimismIcon from '@assets/images/optimism.svg';
import lineaIcon from '@assets/images/linea.png';

export const urlApi = process.env.REACT_APP_API_URL;
export const portApi = 8080;

export const passwordSign = 'test password!';

export const timeGap = 8640000;

const baseChainId = 8453;
const arbitrumChainId = 42161;
const lineaId = 59144;
const optimismId = 10;

export const baseChain: Chain = {
  chainName: 'Base',
  chainId: baseChainId,
  nativeCurrency: { name: 'Ether', decimals: 18, symbol: 'ETH' },
  rpcUrls: ['https://mainnet.base.org'],
  icon: baseIcon,
  alias: 'BASE',
};

export const arbitrumChain: Chain = {
  chainName: 'Arbitrum',
  chainId: arbitrumChainId,
  nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
  rpcUrls: ['https://arb1.arbitrum.io/rpc/'],
  icon: arbitrumIcon,
  alias: 'ARBITRUM',
};

export const linea: Chain = {
  chainId: lineaId,
  chainName: 'Linea',
  nativeCurrency: { name: 'Linea Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: ['https://rpc.linea.build'],
  alias: 'LINEA',
  icon: lineaIcon,
};

export const optimist: Chain = {
  chainId: optimismId,
  chainName: 'Optimism',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: ['https://mainnet.optimism.io'],
  alias: 'LINEA',
  icon: optimismIcon,
};

export const chainIds = [arbitrumChainId, baseChainId, optimismId, lineaId];

export const chains = [arbitrumChain, baseChain, optimist, linea];

const REFUEL_ADDRESS = '0x73f9228039b5c2ce4dcffa32a8712a48283ede9b';

export const contracts: Record<number, {
  VotingCore: string
  Passport: string
  Refuel: string
}> = {
  [baseChainId]: {
    VotingCore: '0xE0e361E1d7935411C204BBF0522BC257742e6c5E',
    Passport: '0x074Ee27819ab426d3e2AF95D82c323f0CaC377E1',
    Refuel: REFUEL_ADDRESS,
  },
  [arbitrumChainId]: {
    VotingCore: '0xE0e361E1d7935411C204BBF0522BC257742e6c5E',
    Passport: '0x074Ee27819ab426d3e2AF95D82c323f0CaC377E1',
    Refuel: REFUEL_ADDRESS,
  },
  [optimismId]: {
    VotingCore: '0xcdc636cc1317571da8b4dd27d3dd7fc551bdd901',
    Passport: '0x74B1B13f6682D5e5e1BA320e0e77D0d1DAF2AFd5',
    Refuel: REFUEL_ADDRESS,
  },
  [lineaId]: {
    VotingCore: '0xe0e361e1d7935411c204bbf0522bc257742e6c5e',
    Passport: '0x074ee27819ab426d3e2af95d82c323f0cac377e1',
    Refuel: REFUEL_ADDRESS,
  },
};

export const ONE_INCH_ORACLE_ADDRESS = '0x07D91f5fb9Bf7798734C3f606dB065549F6893bb';
export const USDT_MAINNET_ADDRESS = '0xdac17f958d2ee523a2206206994597c13d831ec7';

export const decimalPlaces = 4;

export const bnbDecimals = 18;
export const rpvDecimals = 18;

export const gatewayIPFS = 'https://ipfs.io/ipfs/';

export const ACCESS_TOKEN_LS = 'access_token_represent';

export const breakpointMap: { [key: string]: number } = {
  sm: 360,
  md: 768,
  lg: 1440,
  xl: 1920,
};

export const mediaQueries = {
  sm: `@media screen and (min-width: ${breakpointMap.sm}px)`,
  md: `@media screen and (min-width: ${breakpointMap.md}px)`,
  lg: `@media screen and (min-width: ${breakpointMap.lg}px)`,
  xl: `@media screen and (min-width: ${breakpointMap.xl}px)`,
};

export const colors = {
  black: '#000',
  white: '#ffffff',
  yellow: '#cfa345',
  gray: '#A3A2A2',
  gray2: '#a8a8a8',
  grayRgba: 'rgba(148, 148, 148, 0.15)',
  gold: '#E9BB58',
  gold2: '#AB8A43',
  goldRgba: 'rgba(31, 20, 3, 0.4)',
  goldRgba3: 'rgba(164, 105, 13, 0.4)',
  green: '#0c5c08',
  red: '#c72c3b',
  boxShadow: '7px 7px 20px rgba(0, 0, 0, 0.25)',
  gradientModal: 'linear-gradient(89.91deg, #000000 15.66%, #252525 99.94%)',
  textShadow: '14px 14px 50px rgba(0, 0, 0, 0.3)',
  filterDrop:
    'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5)) drop-shadow(0px 4px 4px #000000) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5)) drop-shadow(-8px -7px 35px #000000)',
  boxShadowHover:
    '0px 4px 4px rgba(0, 0, 0, 0.5), 0px 4px 4px #000000, 0px 4px 4px rgba(0, 0, 0, 0.5), -8px -7px 35px #000000',
};
