export default [{ inputs: [], stateMutability: 'nonpayable', type: 'constructor' }, { inputs: [], name: 'EnforcedPause', type: 'error' }, { inputs: [], name: 'ExpectedPause', type: 'error' }, { inputs: [], name: 'InvalidInitialization', type: 'error' }, { inputs: [], name: 'NotInitializing', type: 'error' }, { inputs: [{ internalType: 'address', name: 'owner', type: 'address' }], name: 'OwnableInvalidOwner', type: 'error' }, { inputs: [{ internalType: 'address', name: 'account', type: 'address' }], name: 'OwnableUnauthorizedAccount', type: 'error' }, { inputs: [], name: 'ReentrancyGuardReentrantCall', type: 'error' }, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'partnerWallet', type: 'address',
  }, {
    components: [{ internalType: 'address', name: 'wallet', type: 'address' }, { internalType: 'uint256', name: 'partnerFeeShare', type: 'uint256' }, { internalType: 'uint256', name: 'ownerFeeShare', type: 'uint256' }], indexed: false, internalType: 'struct ZeroWayGasRefuelV1.SAASPartnerSettings', name: 'newPartnerSettings', type: 'tuple',
  }],
  name: 'AddSAASPartner',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'from', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
  }],
  name: 'DepositETH',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'from', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
  }],
  name: 'DepositFallbackETH',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'sender', type: 'address',
  }, {
    indexed: false, internalType: 'address', name: 'target', type: 'address',
  }, {
    indexed: false, internalType: 'uint32', name: 'destinationDomain', type: 'uint32',
  }, {
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
  }, {
    indexed: false, internalType: 'address', name: 'mailbox', type: 'address',
  }, {
    indexed: false, internalType: 'bool', name: 'success', type: 'bool',
  }, {
    indexed: false, internalType: 'bytes', name: 'data', type: 'bytes',
  }],
  name: 'Handle',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint64', name: 'version', type: 'uint64',
  }],
  name: 'Initialized',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
  }],
  name: 'OwnershipTransferred',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'account', type: 'address',
  }],
  name: 'Paused',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'target', type: 'address',
  }, {
    indexed: true, internalType: 'uint32', name: 'destinationDomain', type: 'uint32',
  }, {
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
  }, {
    indexed: false, internalType: 'uint256', name: 'hyperlaneFee', type: 'uint256',
  }, {
    indexed: false, internalType: 'uint256', name: 'serviceFee', type: 'uint256',
  }, {
    indexed: false, internalType: 'bytes32', name: 'messageId', type: 'bytes32',
  }],
  name: 'Refuel',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'partnerWallet', type: 'address',
  }],
  name: 'RemoveSAASPartner',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'actionFee', type: 'uint256',
  }],
  name: 'SetActionFee',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'newMailbox', type: 'address',
  }],
  name: 'SetMailbox',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }, {
    components: [{ internalType: 'uint256', name: 'minRefuelAmount', type: 'uint256' }, { internalType: 'uint256', name: 'maxRefuelAmount', type: 'uint256' }, { internalType: 'uint256', name: 'fixedFee', type: 'uint256' }, { internalType: 'uint256', name: 'basicMultiplier', type: 'uint256' }, { internalType: 'bool', name: 'boundsEnabled', type: 'bool' }, { internalType: 'uint256', name: 'minLiquidityBound', type: 'uint256' }, { internalType: 'uint256', name: 'maxLiquidityBound', type: 'uint256' }, { internalType: 'uint256', name: 'minPercentFeeBound', type: 'uint256' }, { internalType: 'uint256', name: 'maxPercentFeeBound', type: 'uint256' }, { internalType: 'uint256', name: 'emergencyLiquidityMinimum', type: 'uint256' }], indexed: false, internalType: 'struct ZeroWayGasRefuelV1.RefuelSettings', name: 'newRefuelSettings', type: 'tuple',
  }],
  name: 'SetRefuelSettings',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'newServiceFeeVault', type: 'address',
  }],
  name: 'SetServiceFeeVault',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'target', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'currentLiquidity', type: 'uint256',
  }, {
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
  }],
  name: 'TrySendRefuelFailed',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'target', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'currentLiquidity', type: 'uint256',
  }, {
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
  }],
  name: 'TrySendRefuelSuccess',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'account', type: 'address',
  }],
  name: 'Unpaused',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'partnerWallet', type: 'address',
  }, {
    components: [{ internalType: 'address', name: 'wallet', type: 'address' }, { internalType: 'uint256', name: 'partnerFeeShare', type: 'uint256' }, { internalType: 'uint256', name: 'ownerFeeShare', type: 'uint256' }], indexed: false, internalType: 'struct ZeroWayGasRefuelV1.SAASPartnerSettings', name: 'newPartnerSettings', type: 'tuple',
  }],
  name: 'UpdateSAASPartner',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'target', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
  }],
  name: 'WithdrawDebt',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'target', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
  }],
  name: 'WithdrawDebtByOwner',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }, {
    indexed: false, internalType: 'address', name: 'to', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
  }],
  name: 'WithdrawETH',
  type: 'event',
}, {
  inputs: [], name: 'actionFee', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{
    components: [{ internalType: 'address', name: 'wallet', type: 'address' }, { internalType: 'uint256', name: 'partnerFeeShare', type: 'uint256' }, { internalType: 'uint256', name: 'ownerFeeShare', type: 'uint256' }], internalType: 'struct ZeroWayGasRefuelV1.SAASPartnerSettings', name: 'newPartnerSettings_', type: 'tuple',
  }],
  name: 'addSAASPartner',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'target_', type: 'address' }, { internalType: 'uint256', name: 'amount_', type: 'uint256' }, { internalType: 'uint32', name: 'destinationDomain_', type: 'uint32' }], name: 'calculateHyperlaneFee', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'target_', type: 'address' }, { internalType: 'uint256', name: 'amount_', type: 'uint256' }, { internalType: 'uint32', name: 'destinationDomain_', type: 'uint32' }], name: 'calculateRefuelFee', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address[]', name: 'targets_', type: 'address[]' }, { internalType: 'uint256[]', name: 'amounts_', type: 'uint256[]' }, { internalType: 'uint32[]', name: 'destinationDomains_', type: 'uint32[]' }], name: 'calculateRefuelFeeBatch', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'target_', type: 'address' }, { internalType: 'uint256', name: 'amount_', type: 'uint256' }, { internalType: 'uint32', name: 'destinationDomain_', type: 'uint32' }], name: 'calculateRefuelFeeV2', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'target_', type: 'address' }, { internalType: 'uint256', name: 'amount_', type: 'uint256' }, { internalType: 'uint32', name: 'destinationDomain_', type: 'uint32' }], name: 'calculateServiceFee', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'depositETH', outputs: [], stateMutability: 'payable', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: 'cursor_', type: 'uint256' }, { internalType: 'uint256', name: 'howMany_', type: 'uint256' }],
  name: 'fetchSAASPartners',
  outputs: [{
    components: [{ internalType: 'address', name: 'wallet', type: 'address' }, { internalType: 'uint256', name: 'partnerFeeShare', type: 'uint256' }, { internalType: 'uint256', name: 'ownerFeeShare', type: 'uint256' }], internalType: 'struct ZeroWayGasRefuelV1.SAASPartnerSettings[]', name: 'values', type: 'tuple[]',
  }, { internalType: 'uint256', name: 'newCursor', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [], name: 'getFeeMultiplier', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'uint32', name: 'origin_', type: 'uint32' }, { internalType: 'bytes32', name: 'sender_', type: 'bytes32' }, { internalType: 'bytes', name: 'data_', type: 'bytes' }], name: 'handle', outputs: [], stateMutability: 'payable', type: 'function',
}, {
  inputs: [], name: 'hyperlaneClient', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'initialOwner_', type: 'address' }, { internalType: 'address', name: 'mailboxAddr_', type: 'address' }, {
    components: [{ internalType: 'uint256', name: 'minRefuelAmount', type: 'uint256' }, { internalType: 'uint256', name: 'maxRefuelAmount', type: 'uint256' }, { internalType: 'uint256', name: 'fixedFee', type: 'uint256' }, { internalType: 'uint256', name: 'basicMultiplier', type: 'uint256' }, { internalType: 'bool', name: 'boundsEnabled', type: 'bool' }, { internalType: 'uint256', name: 'minLiquidityBound', type: 'uint256' }, { internalType: 'uint256', name: 'maxLiquidityBound', type: 'uint256' }, { internalType: 'uint256', name: 'minPercentFeeBound', type: 'uint256' }, { internalType: 'uint256', name: 'maxPercentFeeBound', type: 'uint256' }, { internalType: 'uint256', name: 'emergencyLiquidityMinimum', type: 'uint256' }], internalType: 'struct ZeroWayGasRefuelV1.RefuelSettings', name: 'refuelSettings_', type: 'tuple',
  }],
  name: 'initialize',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [], name: 'mailboxAddr', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'pure', type: 'function',
}, {
  inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'target_', type: 'address' }, { internalType: 'uint256', name: 'amount_', type: 'uint256' }, { internalType: 'uint32', name: 'destinationDomain_', type: 'uint32' }, { internalType: 'address', name: 'partner_', type: 'address' }], name: 'partnerRefuel', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }, { internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'payable', type: 'function',
}, {
  inputs: [{ internalType: 'address[]', name: 'targets_', type: 'address[]' }, { internalType: 'uint256[]', name: 'amounts_', type: 'uint256[]' }, { internalType: 'uint32[]', name: 'destinationDomains_', type: 'uint32[]' }, { internalType: 'address', name: 'partner_', type: 'address' }], name: 'partnerRefuelBatch', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }, { internalType: 'bytes32[]', name: '', type: 'bytes32[]' }], stateMutability: 'payable', type: 'function',
}, {
  inputs: [], name: 'pause', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'paused', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'target_', type: 'address' }, { internalType: 'uint256', name: 'amount_', type: 'uint256' }, { internalType: 'uint32', name: 'destinationDomain_', type: 'uint32' }], name: 'refuel', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }, { internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'payable', type: 'function',
}, {
  inputs: [{ internalType: 'address[]', name: 'targets_', type: 'address[]' }, { internalType: 'uint256[]', name: 'amounts_', type: 'uint256[]' }, { internalType: 'uint32[]', name: 'destinationDomains_', type: 'uint32[]' }], name: 'refuelBatch', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }, { internalType: 'bytes32[]', name: '', type: 'bytes32[]' }], stateMutability: 'payable', type: 'function',
}, {
  inputs: [], name: 'refuelSettings', outputs: [{ internalType: 'uint256', name: 'minRefuelAmount', type: 'uint256' }, { internalType: 'uint256', name: 'maxRefuelAmount', type: 'uint256' }, { internalType: 'uint256', name: 'fixedFee', type: 'uint256' }, { internalType: 'uint256', name: 'basicMultiplier', type: 'uint256' }, { internalType: 'bool', name: 'boundsEnabled', type: 'bool' }, { internalType: 'uint256', name: 'minLiquidityBound', type: 'uint256' }, { internalType: 'uint256', name: 'maxLiquidityBound', type: 'uint256' }, { internalType: 'uint256', name: 'minPercentFeeBound', type: 'uint256' }, { internalType: 'uint256', name: 'maxPercentFeeBound', type: 'uint256' }, { internalType: 'uint256', name: 'emergencyLiquidityMinimum', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'partnerWallet_', type: 'address' }], name: 'removeSAASPartner', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'saasPartnerSettings', outputs: [{ internalType: 'address', name: 'wallet', type: 'address' }, { internalType: 'uint256', name: 'partnerFeeShare', type: 'uint256' }, { internalType: 'uint256', name: 'ownerFeeShare', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'serviceFeeVault', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: 'actionFee_', type: 'uint256' }], name: 'setActionFee', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'newMailbox_', type: 'address' }], name: 'setMailbox', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{
    components: [{ internalType: 'uint256', name: 'minRefuelAmount', type: 'uint256' }, { internalType: 'uint256', name: 'maxRefuelAmount', type: 'uint256' }, { internalType: 'uint256', name: 'fixedFee', type: 'uint256' }, { internalType: 'uint256', name: 'basicMultiplier', type: 'uint256' }, { internalType: 'bool', name: 'boundsEnabled', type: 'bool' }, { internalType: 'uint256', name: 'minLiquidityBound', type: 'uint256' }, { internalType: 'uint256', name: 'maxLiquidityBound', type: 'uint256' }, { internalType: 'uint256', name: 'minPercentFeeBound', type: 'uint256' }, { internalType: 'uint256', name: 'maxPercentFeeBound', type: 'uint256' }, { internalType: 'uint256', name: 'emergencyLiquidityMinimum', type: 'uint256' }], internalType: 'struct ZeroWayGasRefuelV1.RefuelSettings', name: 'newRefuelSettings', type: 'tuple',
  }],
  name: 'setRefuelSettings',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'newServiceFeeVault_', type: 'address' }], name: 'setServiceFeeVault', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'totalSAASPartners', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'unpause', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{
    components: [{ internalType: 'address', name: 'wallet', type: 'address' }, { internalType: 'uint256', name: 'partnerFeeShare', type: 'uint256' }, { internalType: 'uint256', name: 'ownerFeeShare', type: 'uint256' }], internalType: 'struct ZeroWayGasRefuelV1.SAASPartnerSettings', name: 'newPartnerSettings_', type: 'tuple',
  }],
  name: 'updateSAASPartner',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'userBalance', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'userDebt', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'to_', type: 'address' }], name: 'withdrawDebt', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'target_', type: 'address' }], name: 'withdrawDebtByOwner', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'to_', type: 'address' }, { internalType: 'uint256', name: 'amount_', type: 'uint256' }], name: 'withdrawETH', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, { stateMutability: 'payable', type: 'receive' }] as const;
