import { getContractRefuelFactory } from '@api/ethereum/refuel';
import {
  toastClose, toastError, toastInfo, toastSuccess,
} from '@components';
import { ToastMessages } from '@types';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import { useEffect, useRef, useState } from 'react';
import Web3 from 'web3';
import BN from 'bignumber.js';
import { getWeb3 } from '@api/ethereum';

export type UseRefuelArgs = {
  chainFromId?: number;
  chainToId?: number;
  amount: string;
};

export type UseSufficientDestinationContractBalanceArgs = {
  chainId?: number;
  amount?: string;
};

export type UseRefuelInfoArgs = {
  chainFromId?: number;
  chainToId?: number;
  amount?: string;
};

export type UseMultiRefuelInfoArgs = {
  chainFromId?: number;
  config: {
    amount: string;
    chainId: number;
  }[];
};

export const useRefuel = () => {
  const [isRefueling, setIsRefueling] = useState(false);
  const { account } = useWeb3React();

  const refuel = async ({ chainFromId, chainToId, amount }: UseRefuelArgs) => {
    try {
      // if (!chainFromId) {
      //   addNotification({ title: 'Select current network' });

      //   return;
      // }

      // if (!chainToId) {
      //   addNotification({ title: 'Select destination network' });

      //   return;
      // }

      // if (!amount || +amount <= 0) {
      //   addNotification({ title: 'Enter amount tokens to refuel' });

      //   return;
      // }

      // if (chainFromId == chainToId) {
      //   addNotification({
      //     title: 'Current chain and destination chain should be different',
      //   });

      //   return;
      // }

      // if (chainFromId !== chainId) {
      //   await switchChainAsync({ chainId: chainFromId, connector });
      // }

      if (!chainFromId) {
        return;
      }

      setIsRefueling(true);
      const contract = getContractRefuelFactory(chainFromId);
      const amountToRefuel = Web3.utils.toWei((+amount).toFixed(18));

      const fee = await contract.methods
        .calculateRefuelFee(account, amountToRefuel, chainToId).call();

      const value = BigNumber.from(amountToRefuel).add(fee).toString();

      toastInfo(ToastMessages.TRANSACTION_REQUEST);
      await contract.methods.refuel(account, amountToRefuel, chainToId).send({
        from: account, value,
      });

      setIsRefueling(false);
      toastClose();
      toastSuccess(ToastMessages.TRANSACTION_SUCCESS);
      // const chainFrom = chains.find((chain) => chain.id === chainFromId)?.name;
      // const chainTi = chains.find((chain) => chain.id === chainToId)?.name;

      // if (tx?.status === 1) {
      //   addNotification({
      //     title: `From ${chainFrom} to ${chainTi} success`,
      //     text: 'Status tx on',
      //     link: { url: `${HYPER_LANE_EXPLORER_LINK}${hash}`, text: 'Hyperlane' },
      //     variant: 'success',
      //   });

      //   return;
      // }

      // addNotification({ title: SOMETHING_WENT_WRONG_ERROR_MESSAGE });
    } catch (error: any) {
      setIsRefueling(false);
      console.log(error);
      toastClose();
      toastError(ToastMessages.TRANSACTION_ERROR);
      // addNotification({
      //   title: 'Refuel error',
      //   details: error?.details || error?.message,
      // });
    }
  };

  return { refuel, isRefueling };
};

export const useRefuelSettings = (chainId: number) => {
  const [minRefuelAmount, setMinRefuelAmount] = useState('0');
  const [maxRefuelAmount, setMaxRefuelAmount] = useState('0');

  const getRefuelSettings = async () => {
    try {
      const contract = getContractRefuelFactory(chainId);

      if (!contract) {
        return;
      }

      const response = await contract.methods.refuelSettings().call();

      if (response) {
        setMinRefuelAmount(response.minRefuelAmount);
        setMaxRefuelAmount(response.maxRefuelAmount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (chainId) {
        getRefuelSettings();
      }
    }, 10_000);

    if (chainId) {
      getRefuelSettings();
    }

    return () => clearInterval(interval);
  }, [chainId]);

  return { minRefuelAmount, maxRefuelAmount };
};

export const useMaxRefuelFee = ({ chainFromId, chainToId, amount }: UseRefuelInfoArgs) => {
  const lastRequestRef = useRef<number>();
  const { account } = useWeb3React();
  const [isFetching, setIsFetching] = useState(false);
  const [maxRefuelFee, setMaxRefuelFee] = useState('');

  const getRefuelInfo = async () => {
    if (!chainFromId || !chainToId || chainFromId === chainToId) {
      setMaxRefuelFee('0');

      return;
    }

    if (!account) {
      return;
    }

    if (!amount || +amount <= 0) {
      return;
    }

    const contract = getContractRefuelFactory(chainFromId);

    const requestId = Date.now();
    lastRequestRef.current = requestId;

    const amountToRefuel = Web3.utils.toWei((+amount).toFixed(18) || '0');

    try {
      const refuelFee = await contract.methods.calculateRefuelFee(
        account,
        amountToRefuel,
        chainToId,
      ).call();

      if (requestId === lastRequestRef.current) {
        setMaxRefuelFee(refuelFee.toString());
      }
    } catch (error) {
      console.log(error);
    }

    setIsFetching(false);
  };

  useEffect(() => {
    getRefuelInfo();
  }, [chainFromId, chainToId, amount]);

  return { maxRefuelFee, isFetching };
};

export const useGetRefuelInfo = ({ chainFromId, chainToId, amount }: UseRefuelInfoArgs) => {
  const lastRequestRef = useRef<number>();
  const { account } = useWeb3React();
  const [isFetching, setIsFetching] = useState(false);
  const [refuelCost, setRefuelCost] = useState('');
  const [total, setTotal] = useState('');

  const getRefuelInfo = async () => {
    if (!chainFromId || !chainToId || chainFromId === chainToId) {
      setRefuelCost('');
      setTotal('');

      return;
    }

    const requestId = Date.now();
    lastRequestRef.current = requestId;
    const contractInstance = getContractRefuelFactory(chainFromId);

    const amountToRefuel = Web3.utils.toWei(amount ? (+amount).toFixed(18) : '0');

    try {
      const web3 = getWeb3();
      const refuelFee = await contractInstance.methods.calculateRefuelFee(
        account,
        amountToRefuel,
        chainToId,
      ).call();

      const value = BigNumber.from(amountToRefuel).add(refuelFee).toString();
      const [estimatedGas, gasPrice] = await Promise.all([
        contractInstance.methods.refuel(account, amountToRefuel, chainToId)
          .estimateGas({ value }),
        web3.eth.getGasPrice(),
      ]);

      // console.log('estimatedGas', estimatedGas.toString());
      // console.log('gasPrice', gasPrice.toString());
      // console.log('refuelFee', refuelFee.toString());
      if (requestId === lastRequestRef.current) {
        setRefuelCost(Web3.utils.fromWei(refuelFee));
        setTotal(
          Web3.utils.fromWei(
            BigNumber.from(estimatedGas)
              .mul(gasPrice)
            // .mul(2)
              .add(refuelFee)
              .add(amountToRefuel)
              .toString(),
          ),
        );
      }
    } catch (error) {
      console.log(error);
    }

    setIsFetching(false);
  };

  useEffect(() => {
    if (BN(amount || '').isGreaterThan(0)) {
      getRefuelInfo();
    }
  }, [chainFromId, chainToId, amount]);

  return { refuelCost, total, isFetching };
};
