import { ProposalItem } from './proposal';

export enum ProposalType {
  CryptoVoting = 'CryptoVoting',
  LandBasedVoting = 'LandBasedVoting',
  OrganizationalVoting = 'OrganizationalVoting',

  Created = 'Created',
}

export enum ProposalStatus {
  Active = 'Active',
  Succeed = 'Succeed',
  Failed = 'Failed',
}

export type ProposalRaw = {
  hash: string,
  startedAt: string;
  address: string,
  createdAt: string,
  approvedAt: string,
  status: ProposalStatus,
  // saved: {
  name: string,
  description: string,
  imageHash: string,
  imageMimeType: string,
  creator: string,
  proposalType: ProposalType,
  landCountry: string
  landRegion:string
  landCity: string
  landAdministrativeArea: string
  orgEmployeesHash: string,
  keywords: string[],
  duration: string,
  qtyVoters: string,
  minQtyVoters: string,
  creatorName: string
  creatorWalletAddress: string
  stats:{ for:string, against:string, total: string }

  'id': 'string',
  'emitterAddress': 'string',
  'sender': 'string',
  'destinationDomain': 0,
  'nonce': 0,
  'deadline': 0,
  'signature': 'string',
  'proposalId': 0,
  'totalVoters': 0,
  'minConsensusVoters': 0,
  'whitelist': 'string'

  proposalInfo:{
    'id': '57b04490-bf81-4e29-bb67-8c4ae0055c41',
    'name': 'fff',
    'hash': 'QmfUYawGbuY86FFTMtGDGvKYotVQmoGF5q165saR3oLPBr',
    'blockchainAddress': '0x1111111111111111111111111111111111111111',
    'description': 'asdasd',
    'creatorWalletAddress': '0x1592aA4Ee83bD993De00dB9d26f22a9DEDc659E6',
    'imageHash': 'QmdhKYtUmjKmb2fFSpeMuCAt2zVTxiAthDfY9eNiR62DsG',
    'imageMimeType': 'data:image/png;base64',
    'creator': '2e4a7645-890e-4f69-8a7a-25450c5b3842',
    'proposalType': 'CryptoVoting',
    'employeesHash': '',
    'landCountry': null,
    'landRegion': null,
    'landCity': null,
    'landAdministrativeArea': null,
    'duration': '2334960',
    'qtyVoters': '1212',
    'minPercentageVoters': '1',
    'keywords': [
      'sadas',
    ],
    'status': 'active',
    'statsId': null,
    'expiresAt': '2025-01-03T06:21:19.155Z',
    'approvedAt': null,
    'startedAt': '2024-12-02T15:23:58.346Z',
    'closedAt': null,
    'createdAt': '2024-12-02T15:23:58.346Z'
  }
  // }
};

export type ProposalListResponse = {
  list: ProposalItem[],
  total: number
};
